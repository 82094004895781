export * from './types';
export * from './serviceResources';
import * as serviceUtils from './serviceUtils';
import * as multipartServiceUtils from './multipartServiceUtils';

export const utils = serviceUtils;
export const multipartUtils = multipartServiceUtils;

export type {
	RequestServiceResult,
	PartsGenerator,
	MultiPartFetchResult,
	BodyResult,
} from './multipartServiceUtils';
